<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Portada - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">

              <b-tabs content-class="mt-3">
                <b-tab title="Español" active>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Titulo:">
                        <b-form-input  v-model="cover_page.title_es" ></b-form-input>
                        <small v-if="errors.title_es"  class="form-text text-danger" >Ingrese un titulo</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Descripción:">
                        <b-form-input  v-model="cover_page.description_es"></b-form-input>
                      </b-form-group>
                    </b-col>
                    
                  </b-row>
                </b-tab>
                <b-tab title="Ingles">
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Titulo:">
                        <b-form-input  v-model="cover_page.title_en" ></b-form-input>
                        <small v-if="errors.title_en"  class="form-text text-danger" >Ingrese un titulo</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Descripción:">
                        <b-form-input  v-model="cover_page.description_en"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>

              <b-row>
                <b-col md="10">
                  <b-form-group label="Imagen:">
                    <b-form-file
                      accept="image/png, image/jpeg"
                      v-model="file"
                      :state="Boolean(file)"
                      @change="onFileChange"
                      placeholder="Seleccione una imagen"
                      drop-placeholder="Arrastre la imagen aqui"
                    ></b-form-file>
                    <small v-if="errors.image"  class="form-text text-danger" >Seleccione una imagen</small>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Estado:">
                    <b-form-select  v-model="cover_page.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>



    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";

// components
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  components:{
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'CoverPage',
      role: 2,
      cover_page: {
          id_cover_page:'',
          title_es:'',
          description_es:'',
          title_en:'',
          description_en:'',
          image:'',
          state:1,
      },
      file:null,
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],

      errors: {
        title_es: false,
        description_es: false,
        title_en: false,
        description_en: false,
        image: false,
      },
      validate: false,
    };
  },
  mounted() {

  },
  methods: {
    AddCoverPage,
    Validate,
    onFileChange,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function onFileChange(e) {
  this.cover_page.image = e.target.files[0];
}



function AddCoverPage() {
  let me = this;
  let url = me.url_base + "covers-page/add";
  let data = new FormData();
  data.append("title_es", me.cover_page.title_es);
  data.append("description_es", me.cover_page.description_es);
  data.append("title_en", me.cover_page.title_en);
  data.append("description_en", me.cover_page.description_en);
  data.append("image", me.cover_page.image);
  data.append("state", me.cover_page.state);
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {
          me.cover_page.title_es = '';
          me.cover_page.description_es = '';
          me.cover_page.title_en = '';
          me.cover_page.description_en = '';
          me.cover_page.image = '';
          me.cover_page.state = 1;
          me.file = null;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  this.errors.title_es = this.cover_page.title_es.length == 0 ? true : false;
  this.errors.title_en = this.cover_page.title_en.length == 0 ? true : false;
  this.errors.image = this.cover_page.image.length == 0 ? true : false;
  this.errors.state = this.cover_page.state.length == 0 ? true : false;

  if (this.errors.title_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.title_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.image) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.state) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  

  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar la portada ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddCoverPage();
    }
  });
}
</script>
